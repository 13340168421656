var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"S4GP3JVWVdq9mLCMtDKcz"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import getConfig from 'next/config';
import { init } from '@flexteam/sentry';

const isServer = process.env.NEXT_IS_SERVER === 'true';
const isProductionBuild = process.env.IS_PRODUCTION_BUILD === 'true';

const { SENTRY_DSN, NEXT_PUBLIC_SENTRY_RELEASE, STAGE } =
  getConfig().publicRuntimeConfig;

init({
  isProductionBuild,
  isServer,
  dsn: SENTRY_DSN,
  release: NEXT_PUBLIC_SENTRY_RELEASE,
  environment: STAGE,
});
